import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EstafetaService {

  constructor(
    private http: HttpClient
  ) { }

  public ObtenerEtiqueta(idpedido: string) {
    return this.http.get('/api/etiqueta/' + idpedido);
  }

  public CrearEtiqueta(pedido: any, numeroRecibo: number, idPedido: number) {
    return this.http.post('/api/etiqueta', { pedido: pedido, numeroRecibo: numeroRecibo, idPedido: idPedido });
  }

  public GetManifiesto() {
    return this.http.get('/api/manifiesto');
  }

  public CrearEtiquetaExtra(pedido: any, numeroRecibo: number, idPedido: number) {
    return this.http.post('/api/etiqueta_extra', { pedido: pedido, numeroRecibo: numeroRecibo, idPedido: idPedido });
  }

  public Get_etiqueta_por_guia(guia) 
  {
    return this.http.get('/api/get_etiqueta_por_guia/'+guia)
  }

  public Get_ultima_etiqueta(idpedido)
  {
    return this.http.get('/api/get_ultima_etiqueta/'+idpedido)
  }

}
