import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OracleService {

  constructor(private http: HttpClient) { }

  public Valida_Codigo(codigo) {
    
    return this.http.post('/api/validacodigo',{codigo:codigo})
  }

  public Obtener_Precio_Articulo_Cliente_Final(codigo){
    return this.http.post('/api/obtenerprecioarticulocf',{codigo:codigo})
  }

  public Obtener_Precio_Articulo_Adi(codigo){
    return this.http.post('/api/obtenerprecioarticuloadi',{codigo:codigo})
  }

  public Get_all_products(linea,sublinea,familia,catalogo){
    return this.http.get('api/Get_all_products/' + linea + '/' + sublinea + '/' + familia + '/' + catalogo)
  }

  public Get_ubicacion_articulo(sku)
  {
    return this.http.get('api/get_ubicacion_articulo/'+sku)
  }

  public Get_referencia_bancaria(codcliente)
  {
    return this.http.get('api/get_referencia_bancaria/'+codcliente)
  }

  public Get_saldo_en_banco(referencia)
  {
    return this.http.get('api/get_saldo_en_banco/'+referencia)
  }

  public Get_ordenes_ap()
  {
    return this.http.get('api/get_ordenes_ap')
  }

  public Post_obtener_tickets_generados(recibos)
  {
    return this.http.post('api/post_obtener_tickets_generados',{recibos:recibos})
  }

  public Get_os_por_numero_recibo(recibo)
  {
    return this.http.get('api/get_os_por_numero_recibo/'+recibo)
  }

  public Get_ticket_por_numero_recibo(recibo)
  {
    return this.http.get('api/get_ticket_por_numero_recibo/'+recibo)
  }

  public Get_tarjetas_por_codcliente(codcliente)
  {
    return this.http.get('api/get_tarjetas_por_codcliente/'+codcliente)
  }

  public Get_afiliaciones_ultima_semana()
  {
    return this.http.get('api/get_afiliaciones_ultima_semana')
  }

  public Get_eliminar_pedido_reservado(numero_recibo)
  {
    return this.http.get('api/get_eliminar_pedido_reservado/'+numero_recibo)
  }
  public Get_eliminar_pedido_reservadoxcodigo(numero_recibo, codigo)
  {
    return this.http.get('api/get_eliminar_pedido_reservadoxcodigo/'+numero_recibo+'/'+codigo)
  }

  public Get_numero_titular_por_codcliente(codcliente)
  {
    return this.http.get('api/get_numero_titular_por_codcliente/'+codcliente)
  }
  public Get_buscar_datos_titular(titular)
  {
    return this.http.get('api/get_buscar_datos_titular/'+titular)
  }

  public Get_obtener_linea_credito(cai)
  {
    return this.http.get('api/get_obtener_linea_credito/'+cai)
  }
  public Obtener_lista_tickets(numeros_de_recibo) {    
    return this.http.post('/api/Obtener_lista_tickets',{numeros_de_recibo:numeros_de_recibo})
  }
  public ObtenerPrecioArticuloTodos(codigos,cantidad,price_list) {    
    return this.http.post('/api/ObtenerPrecioArticuloTodos',{codigos:codigos,cantidad:cantidad,price_list:price_list})
  }
  public get_datos_adi_correo(correo)
  {
    return this.http.get('api/get_datos_adi_correo/'+correo)
  }
  
}
