import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReporteService {

  constructor(
    private http: HttpClient
  ) { }

  public getorigen(){
    return this.http.get('api/getorigen');
  }
  public pedidosReporte(where: string) {
    return this.http.post('/api/pedidosReporte/',{where:where});
  }
 
}
