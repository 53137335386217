import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PromocionesService {

  constructor(
    private http: HttpClient
  ) { }

  public getProductosDescontinuados() {
    return this.http.get('/api/productosDescontinuados');
  }

  public deleteProductosDescontinuados() {
    return this.http.delete('/api/productosDescontinuados');
  }

  public insertProductosDescontinuados(producto)
  {
    return this.http.post('/api/insertDescontinuados',{producto: producto});
  }

  public getProductosVigentes() {
    return this.http.get('/api/productosVigentes');
  }

  public deleteProductosVigentes() {
    return this.http.delete('/api/productosVigentes');
  }

  public insertProductosVigentes(producto)
  {
    return this.http.post('/api/insertVigentes',{producto: producto});
  }
  public allProductosVigentes(producto)
  {
    return this.http.post('/api/allVigentes',{producto: producto});
  }
  public enviogratis(fechas)
  {
    return this.http.put('/api/enviogratis',{fechas: fechas});
  }
  public getMeses()
  {
    return this.http.get('/api/getmeses');
  }
  public activameses(activameses)
  {
    return this.http.put('/api/activameses',{activameses: activameses});
  }
  public detallemeses(detallemeses)
  {
    return this.http.put('/api/detallemeses',{detallemeses: detallemeses});
  }
  public getProductos2x1() 
  {
    return this.http.get('/api/productospromocion2x1');
  }
  public insertProducto2x1(producto)
  {
    return this.http.post('/api/insertProducto2x1',{producto: producto});
  }
  public updatepromocion(fechas){
    return this.http.put('/api/updatepromocion2x1',{fechas: fechas});
  }
  public deleteProductos2x1(){
    return this.http.delete('/api/deleteProductos2x1');
  }
  public GetCatalogos(){
    return this.http.get('/api/getCatalogos');
  }
  public VigentesPorCatalogo(producto)
  {
    return this.http.post('/api/VigentesPorCatalogo',{producto: producto});
  }
  public GetSublinea(){
    return this.http.get('/api/getSublinea');
  }
  public VigentesPorSublinea(producto)
  {
    return this.http.post('/api/VigentesPorSublinea',{producto: producto});
  }
}
