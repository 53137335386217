import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ProductosService {

  constructor(private http: HttpClient) { }


	public BuscarProducto(codigo: string) {
    	return this.http.get('/api/buscarProducto/' + codigo);
  	}


  public Get_keywords()
  {
    return this.http.get('api/get_keywords');
  }

  public Get_familias()
  {
    return this.http.get('api/get_familias')
  }

  public Insert_producto(producto)
  {
    return this.http.post('api/insert_producto',{producto: producto})
  }

  public Get_productos()
  {
    return this.http.get('api/get_productos')
  }

  public Get_catalogos_activos()
  {
    return this.http.get('api/get_catalogos_activos')
  }

  public Get_catalogos_inactivos()
  {
    return this.http.get('api/get_catalogos_inactivos')
  }

  public Insert_fecha_lanzamiento_catalogo(catalogo){
    return this.http.post('api/insert_fecha_lanzamiento_catalogo',{catalogo:catalogo})
  }

  public Update_desactiva_catalogo(catalogo){
    return this.http.post('api/update_desactiva_catalogo',{catalogo:catalogo})
  }

  public Update_activar_codigo(producto)
  {
    return this.http.post('api/update_activar_codigo',{producto:producto})
  }

  public Update_desactivar_codigo(codigo)
  {
    return this.http.post('api/update_desactivar_codigo',{codigo:codigo})
  }
  public Get_Continue(codigo)
  {
    return this.http.get('api/Get_Continue/' + codigo)
  }
  public update_product(codigo,precio,precio_adi)
  {
    return this.http.post('api/update_product',{
      codigo:codigo,
      precio:precio,
      precio_adi:precio_adi
    })
  }
  public update_all_product(producto)
  {
    // ASSIGN DEFAULT VALUE 0 TO THE PRICES IF NULL WHEN UPDATING
    producto.preciocf = producto.preciocf ? producto.preciocf : 0;
    producto.precioadi = producto.precioadi ? producto.preciocf : 0;

    // ASSIGN DEFAULT DATE VALUE IF NULL WHEN UPDATING
    producto.fecha_lanzamiento = producto.fecha_lanzamiento ? producto.fecha_lanzamiento : '2001-01-01';

    return this.http.post('api/update_all_product',{producto:producto})
  }
  public delete_tags(codigo)
  {
    return this.http.delete('api/delete_tags/' + codigo)
  }
  public delete_submenu(codigo)
  {
    return this.http.delete('api/delete_submenu/' + codigo)
  }

  public Get_obtener_top_productos_clickeados(cantidad)
  {
    return this.http.get('api/obtener_top_productos_clickeados/'+cantidad)
  }

  public Get_obtener_top_productos_menos_clickeados(cantidad)
  {
    return this.http.get(`api/obtener_top_productos_menos_clickeados/${cantidad}`)
  }

  public Get_obtener_top_articulos_vendidos(cantidad, orden)
  {
    return this.http.get(`api/get_top_articulos_vendidos/${cantidad}/${orden}`)
  }
  public get_est(codigo, categoria)
  {
    return this.http.get('api/get_est/' + codigo + '/' + categoria)
  }
  public get_key(codigo)
  {
    return this.http.get('api/get_key/' + codigo)
  }
  public get_menus(codigo)
  {
    return this.http.get('api/get_menus/' + codigo)
  }
  public get_tipo_productos_especiales()
  {
    return this.http.get('api/get_tipo_productos_especiales')
  }
  public get_list_productos_especiales(Tipo)
  {
    return this.http.get('api/get_list_productos_especiales/' + Tipo)
  }
  public insert_productos_especiales(producto)
  {
    return this.http.post('api/insert_productos_especiales',{producto:producto})
  }
  public delete_coleccion_productos_especiales(tipo)
  {
    return this.http.delete('api/delete_coleccion_productos_especiales/' + tipo )
  }
  public insert_productos_especiales_por_catalogo(producto)
  {
    return this.http.post('api/insert_productos_especiales_por_catalogo',{producto:producto})
  }
  public insert_productos_especiales_por_sublinea(producto)
  {
    return this.http.post('api/insert_productos_especiales_por_sublinea',{producto:producto})
  }
  public delete_producto_especial(tipo,codigo)
  {
    return this.http.delete('api/delete_producto_especial/'+tipo+'/'+codigo)
  }

  public InsertUsuarioCarga(info)
  {
    return this.http.post('api/insert_usuario_carga',{info:info})
  }
  public ObtenerCatalogos()
  {
    return this.http.get('api/catalogos');
  }
  public Get_productos_by_catalog(catalogo)
  {
    return this.http.post('api/get_productos_by_catalog',{catalogo:catalogo})
  }

}
