import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PedidosService {

  constructor(
    private http: HttpClient
  ) { }

  public getPedidosPendientes() {
    return this.http.get('/api/pedidosPendientes');
  }

  public getPedidosASurtir() {
    return this.http.post('/api/pedidosASurtir', { });
  }

  public getPedidosHisorial() {
    return this.http.get('/api/pedidosHistorial');
  }

  public searchPedido(keyword: string) {
    return this.http.get('/api/buscarPedidoHistorial/' + keyword);
  }

  public marcarPagado(idPedido: string) {
    return this.http.put('/api/marcarPagado/' + idPedido, {});
  }

  public marcarPagadoMP(idPedido: number, numeroDeRecibo: string, usuario: string) {
    return this.http.put('/api/marcarPagadoMP/' + idPedido + '/' + numeroDeRecibo + '/' + usuario, {});
  }

  public cancelarPedido(idPedido: number, usuario: string) {
    return this.http.put('/api/cancelarPedido/' + idPedido + '/' + usuario, {});
  }

  public getListaArticulos(idPedido: number) {
    return this.http.get('/api/listaArticulos/' + idPedido);
  }
  public getDetalle(idPedido: number) {
    return this.http.get('/api/getDetalle/' + idPedido);
  }

  public getInfoPedido(idPedido: number) {
    return this.http.get('/api/pedido/' + idPedido);
  }

  public getPedidosEnviados() {
    return this.http.get('/api/pedidosEnviados');
  }

  public get_carritos_abandonados(cantidad: number) {
    return this.http.get(`/api/get_carritos_abandonados/${cantidad}`)
  }

  public insertaReferenciaOracle(numero_orden, referencia, origen) {
    return this.http.post('api/insertaReferenciaOracle', {
      numero_orden: numero_orden,
      referencia: referencia,
      origen: origen
    })
  }

  public cancelar_pedido_obs(idpedido, obs, usuario, fecha) {
    return this.http.put('api/cancelar_pedido_obs', { idpedido: idpedido, obs: obs, usuario: usuario, fecha: fecha })
  }

  public get_pedidos_cancelados(fecha_inicio, fecha_fin) {
    return this.http.get('api/pedidos_cancelados/' + fecha_inicio + "/" + fecha_fin)
  }

  public get_existe_guia(guia) {
    return this.http.get('api/existe_guia/' + guia)

  }

  public put_guia_manual(guia, idpedido, recibo) {
    return this.http.put('api/post_insertar_guia_manual', { guia: guia, idpedido: idpedido, recibo: recibo })
  }

  public get_pedidos_pendientes() {
    return this.http.get('api/pedidos_por_pagar')
  }

  public post_obtener_pedidos_por_recibo_sin_guia() {
    return this.http.post('api/post_obtener_pedidos_por_recibo_sin_guia', {  })
  }

  public post_obtener_pedidos_por_recibo_sin_guia_filtro(origen,impresa) {
    return this.http.post('api/post_obtener_pedidos_por_recibo_sin_guia_filtro', { origen:origen,impresa:impresa  })
  }

  public post_obtener_pedidos_por_recibo_con_guia(fecha_inicio, fecha_fin) {
    return this.http.post('api/post_obtener_pedidos_por_recibo_con_guia', { fecha_inicio: fecha_inicio, fecha_fin: fecha_fin })
  }


  public get_datos_facturacion(idpedido) {
    return this.http.get('api/get_datos_facturacion/' + idpedido)
  }

  public get_detalle_pedido_sca(idpedido) {
    return this.http.get('api/get_detalle_pedido_sca/'+idpedido)
  }

  public put_solicitud_creada(idpedido)
  { 
    return this.http.put('api/put_solicitud_creada/'+idpedido, {})
  }

  public estatus_surtido(idPedido: number, estatus: number) {
    return this.http.put('/api/estatus_surtido/', {idPedido:idPedido, estatus:estatus});
  }

  public estatus_envio(idPedido: number, estatus: number, codigo: number) {
    return this.http.put('/api/estatus_enviado/',{idPedido:idPedido, estatus:estatus, codigo:codigo});
  }

  public getPedidosIncompletos() {
    return this.http.get('/api/pedidos_incompletos');
  } 
  
  public get_guias(idpedido) {
    return this.http.get('/api/get_guias/'+idpedido);
  }  

  public getPedidosASurtir_con_filtro(filtro) {
    return this.http.post('/api/pedidosASurtirfiltro', {filtro:filtro });
  }

  public post_obtener_pedidos_por_recibo_con_guia_filtro(fecha_inicio, fecha_fin, origen) {
    return this.http.post('api/post_obtener_pedidos_por_recibo_con_guia_filtro', { fecha_inicio: fecha_inicio, fecha_fin: fecha_fin, origen: origen })
  }

  public get_pedidos_cancelados_filtro(fecha_inicio, fecha_fin, origen) {
    return this.http.get('api/pedidos_cancelados_filtro/' + fecha_inicio + "/" + fecha_fin + "/" + origen)
  }

  public metodos_pago(idPedido: number) {
    return this.http.get('/api/metodos_pago/' + idPedido);
  }
  
}
