import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ClientesService {

  constructor(
    private http: HttpClient
  ) { }

  public getClientePorPedido(idPedido) {
    return this.http.get('/api/clientePedido/' + idPedido);
  }

  public get_edad_clientes()
  {
    return this.http.get('/api/get_edad_clientes')
  }

  public get_genero_clientes()
  {
    return this.http.get('/api/get_genero_clientes')
  }

  public get_estado_clientes()
  {
    return this.http.get('/api/get_estado_clientes')
  }

}
